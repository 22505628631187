<template>
  <div class="rounded-sm bg-gray-200 p-1 -mx-2">
    <div class="rounded-sm bg-gray-400 p-1">
      <div class="rounded-sm bg-gray-900 p-1 text-white flex items-center">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'OpacityButton'
}
</script>

<template>
  <div
    class="font-extrabold"
    :class="size"
  >
    <slot />
  </div>
</template>

<script>

export default {
  name: 'BigTitle',
  props: {
    large: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    size () {
      if(this.large) {
        return 'text-4xl'
      }

      return 'text-2xl'
    }
  }
}
</script>

<template>
  <textarea
    :maxlength="maxlength"
    :minlength="minlength"
    :value="value"
    :class="[classes,resize,'dark:text-white px-4 py-2 dark:shadow-none border border-gray-300 focus:outline-none focus:ring-0']"
    :placeholder="placeholder"
    @input="$emit('input', $event)"
  />
</template>

<script>

export default {
  name: 'BaseTextarea',
  props: {
    value: { type: String, default: '' },
    resize: { type: String, default: 'resize' },
    placeholder: { type: String, default: '' },
    classes: { type: String, default: 'rounded-sm' },
    maxlength: { type: Number, default: 500 },
    minlength: { type: Number, default: 0 }
  }
}
</script>

<template>
  <div
    class="flex flex-shrink-0 justify-center items-center mr-2 relative"
    :class="defaultClass"
  >
    <div
      v-if="avatar && avatar.image && avatar.image !== ''"
      class="bg-gray-400 absolute inset-0 bg-center rounded-full border bg-no-repeat border-gray-300 bg-cover"
      :style="'background-image: url(\'' + avatar.image + '\');'"
    />
    <AvatarInitials
      v-else
      :name="fullName"
      :class="defaultClass"
      :size="size"
    />
    <div
      v-if="useReputation"
      class="absolute bottom-0 right-0 -mr-1 -mb-1"
    >
      <div class="rounded-lg px-1 star-margin bg-black text-white border-2 border-white font-reputation text-4xs">
        {{ (stockUser.reputation !== undefined) ? abbreviateInteger(stockUser.reputation) : 0 }} R
      </div>
    </div>
  </div>
</template>

<script>
var NumAbbreviate = require('number-abbreviate')

export default {
  name: 'Avatar',
  props: {
    user: {
      type: Object,
      default: () => {}
    },
    stockUser: {
      type: Object,
      default: () => {}
    },
    company: {
      type: Object,
      default: () => {}
    },
    rounded: {
      type: Boolean,
      default: true
    },
    defaultClass: {
      type: String,
      default: 'w-10 h-10'
    },
    size: {
      type: Number,
      default: 40
    },
    useReputation: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    avatar () {
      if (this.stockUser) {
        return this.stockUser
      }

      if (this.company) {
        return this.company
      }
      if (!this.user) {
        return null
      }

      return this.user.profile
    },
    fullName () {
      if (!this.avatar) {
        return 'G'
      }

      if (this.company) {
        return this.avatar.name
      }

      if (this.avatar.user) {
        if (this.avatar.user.profile_type === 'App\\Models\\GuestUser') {
          return this.avatar.name
        }
      }

      return this.avatar.username
    }
  },
  methods: {
    abbreviateInteger (number) {
      let abbr = new NumAbbreviate([' K', ' M', ' MD', ' T'])

      return String(abbr.abbreviate(number, 0))
    }
  }
}
</script>

<style lang="scss">
  .star-margin {
    padding-top: 2px;
  }
</style>

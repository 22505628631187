export const isMobile = () => {
  return /iPhone|iPad|iPod|Android|IEMobile|WPDesktop|BlackBerry|Opera Mini/i.test(navigator.userAgent) || window.innerWidth < 400
}

export const detectBrowser = () => {
  let browser

  if ((navigator.userAgent.indexOf('Opera') || navigator.userAgent.indexOf('OPR')) !== -1) {
    browser = 'opera'
  } else if (navigator.userAgent.indexOf('Chrome') !== -1) {
    browser = 'chrome'
  } else if (navigator.userAgent.indexOf('Safari') !== -1) {
    browser = 'safari'
  } else if (navigator.userAgent.indexOf('Firefox') !== -1) {
    browser = 'firefox'
  } else if ((navigator.userAgent.indexOf('MSIE') !== -1) || (!!document.documentMode === true)) {
    browser = 'ie'// crap
  } else {
    browser = 'Unknown'
  }

  localStorage.setItem('browser', browser)

  return browser
}

export const iPhoneVersion = () => {
  var ratio = window.devicePixelRatio || 1

  var iHeight = window.screen.height * ratio
  var iWidth = window.screen.width * ratio

  if (iWidth === 414 && iHeight === 896) {
    return 'Xmax-Xr'
  } else if (iWidth === 375 && iHeight === 812) {
    return 'X-Xs'
  } else if (iWidth === 320 && iHeight === 480) {
    return '4'
  } else if (iWidth === 375 && iHeight === 667) {
    return '6'
  } else if (iWidth === 414 && iHeight === 736) {
    return '6+'
  } else if (iWidth === 320 && iHeight === 568) {
    return '5'
  } else if (iHeight <= 480) {
    return '2-3'
  }
  return 'none'
}

export const isIphone = () => {
  return !!navigator.userAgent.match(/iPhone/i)
}

export default {
  isMobile,
  isIphone,
  iPhoneVersion,
  detectBrowser
}

<template>
  <div
    class="w-full relative"
  >
    <div
      class="absolute inset-0 bg-center bg-no-repeat"
      :class="[type, backgroundColor, backgroundColor !== '' ? 'p-4' : '']"
      :style="backgroundColor ? '' : 'background-image: url(' + src + ')'"
    >
      <div
        class="w-full h-full bg-center bg-no-repeat"
        :class="[type]"
        :style="backgroundColor ? 'background-image: url(' + src + ')' : ''"
      />
    </div>
    <div
      :class="ratio"
    />
  </div>
</template>

<script>
export default {
  name: 'BannerImage',
  props: {
    src: { type: String, default: '' },
    backgroundColor: { type: String, default: '' },
    ratio: {
      type: String,
      default: 'aspect-w-16 aspect-h-9'
    },
    type: {
      type: String,
      default: 'bg-cover'
    }
  }
}
</script>

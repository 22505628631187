<template>
  <div class="logo font-header font-bold flex flex-col text-center items-center text-custom-light-header dark:text-custom-dark-header">
    <img
      :class="small ? 'w-20 mb-2' : 'w-52'"
      :src="mode === 'lightest' ? '/logo-lightest.png' : 'light' ? '/logo.svg' : '/logo-dark.png'"
    >
  </div>
</template>

<script>

export default {
  name: 'Logo',
  props: {
    small: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'light'
    }
  }
}
</script>

<template>
  <div
    class="relative flex flex-col"
  >
    <ProductsContainer />
  </div>
</template>

<script>
import { useAppStore } from '@/stores/app'
import ProductsContainer from '@/modules/products'

export default {
  name: 'Home',
  components: {
    ProductsContainer,
  },
  setup () {
    const appStore = useAppStore()

    return { appStore }
  }
}
</script>

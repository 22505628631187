<template>
  <div
    class="mb-2"
    :class="wrapper"
  >
    <div
      class="shadow-sm py-4 cursor-pointer"
      @click="open = !open"
    >
      <h2 class="flex text-primary text-sm items-center justify-between m-0">
        <slot name="question" />
        <div class="px-4">
          <Icon
            v-if="marker && open"
            name="caret-up"
          />
          <Icon
            v-if="marker && !open"
            name="caret-down"
          />
        </div>
      </h2>
      <div
        class="answer"
        :class="{'hidden': !open}"
      >
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Accordion',
  props: {
    wrapper: { type: String, default: 'accordion-wrapper' },
    container: { type: String, default: 'accordion-inner' },
    marker: { type: Boolean, default: true },
    opened: { type: Boolean, default: false }
  },
  data () {
    return {
      open: this.opened
    }
  }
}
</script>

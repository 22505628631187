<template>
  <button class="py-3 px-6 rounded-sm">
    <slot />
  </button>
</template>

<script>

export default {
  name: 'BaseButton',
  props: {
  }
}
</script>

 import { format,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  differenceInWeeks
} from 'date-fns'

// import { i18n } from '@/js/language/'

export const diffFromNow = (datetime) => {
  const now = new Date()
  const current = new Date(datetime)

  const seconds = differenceInSeconds(now, current)
  if (seconds < 60) {
    return seconds.toString() + 's'
  }

  const minutes = differenceInMinutes(now, current)
  if (minutes < 60) return minutes.toString() + 'm'

  const hours = differenceInHours(now, current)
  if (hours < 24) return hours.toString() + 'h'

  const days = differenceInDays(now, current)
  if (days < 7) return days.toString() + 'd'

  const weeks = differenceInWeeks(now, current)
  if (weeks < 4) return weeks.toString() + 'w'

  return format(current, 'yyyy-MM-dd kk:mm')
}

export default { diffFromNow }

<template>
  <div
    class="relative flex flex-col"
  >
    <Container
      wide
      class="z-20 relative"
      padding="p-0"
    >
      <ContactContainer />
    </Container>
  </div>
</template>

<script>
import { useAppStore } from '@/stores/app'
import ContactContainer from '@/modules/contactWindon'

export default {
  name: 'Contact',
  components: {
    ContactContainer
  },
  setup () {
    const appStore = useAppStore()

    return { appStore }
  }
}
</script>

<template>
  <div />
</template>

<script>
export default {
  name: 'Gradient'
}
</script>

<style scoped>
div {
  overflow: hidden;
  background: white;
  background: linear-gradient(45deg, white, #de5976);
  min-height: 100%;
  width: 100%;
  height: 100px;
  position: relative;
  z-index: 1;
  box-shadow: inset -1px -50px 50px -10px white, inset 0px -1px 0px 0px white;
}

div:before,
div:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
}
div:before {
  background: #de5976;
  box-shadow: 0 0 240px 64px #2c1c86;
  z-index: 2;
  top: -100%;
  opacity: 0.8;
  left: -52%;
  opacity: 50%;
}
div:after {
  background: white;
  z-index: 3;
  bottom: -96%;
  right: -72%;
  box-shadow: -100px 0 100px 134px white;
  opacity: 1;
  border-radius: 100%;
}
</style>

<template>
  <div
    :class="[background, border, shadow, !to ? padding : 'hover:bg-gray-100 hover:dark:bg-custom-old-purple-300']"
    class="flex flex-col"
    @click="$emit('click')"
  >
    <component
      :is="to ? 'RouterLink' : 'div'"
      :to="to ? to : undefined"
      :class="to ? 'p-4' : 'h-full'"
    >
      <div
        :class="body"
      >
        <slot />
      </div>
    </component>
  </div>
</template>

<script>

export default {
  name: 'BaseCard',
  props: {
    padding: { type: String, default: 'p-4' },
    justify: { type: String, default: 'start' },
    to: { type: Object, default: () => {} },
    items: { type: String, default: 'start' },
    body: { type: String, default: 'flex flex-col flex-grow h-full' },
    background: { type: String, default: 'bg-white rounded rounded-md dark:bg-custom-old-purple-900' },
    border: { type: String, default: 'border border-gray-100 dark:border-custom-old-purple-900' },
    shadow: { type: String, default: 'shadow-sm dark:shadow-sm-dark' }
  },
  emits: ['click']
}
</script>

import { defineStore } from 'pinia'
import RepositoryFactory from '@/api/repositoryFactory'
const repository = RepositoryFactory.get('news')

export const useNewsStore = defineStore('news', {
  state: () => ({
    news: [],
    loading: false
  }),
  actions: {
    async fetchAction () {
      this.loading = true
      try {

        const response = await repository.fetch()
        console.log(response)
        console.log(response.data)
        // const json = await response
        this.loading = false
        
        this.news = [...response.data.channel.item]
      } catch (e) {
        this.loading = false
      }
    }
  }
})

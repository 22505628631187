<template>
  <div class="bg-white hover:bg-gray-100 cursor-pointer p-4 border rounded-md flex items-center justify-between">
    <div class="flex items-center">
      <slot />
    </div>
    <Icon
      name="download"
      class="text-xl mr-2"
    />
  </div>
</template>

<script>

export default {
  name: 'DownloadButton',
  props: {
    src: {
      type: String,
      default: ''
    }
  }
}
</script>
